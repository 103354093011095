<template>
  <div class="home">
    <div class="home-title">
      服务内容
    </div>
    <div class="home-content">
      <div v-for="(server, index) in serverList" :key="index" class="home-content-item" @click="to(index)">
        <el-image class="img" :src="server.img"></el-image>
        {{ server.name }}
      </div>
    </div>
    <div id="excellent" class="home-title">
      能力优势
    </div>
    <div class="excellent">
      <div v-for="(excellent, index) in excellentList" :key="index" class="excellent-item"
        :class="{ reverse: index % 2 == 0 }">
        <el-image class="img" fit="cover" :src="excellent.img"></el-image>
        <div class="excellent-item-text">
          <div class="name">{{ excellent.name }}</div>
          <div class="label">
            {{ excellent.label }}
          </div>
          <div class="position">0{{ index + 1 }}</div>
        </div>
      </div>
    </div>
    <div class="home-title">
      公司介绍
    </div>
    <div class="home-firm">
      <div class="backg"></div>
      <div class="home-firm-info">
        <div class="name">吉林省创实信息技术有限责任公司</div>
        <div class="text">
          <div>
            吉林省创实信息技术有限责任公司是一家以互联网技术为核心的电子商务运营商。公司采用先进的网络技术与严谨的管理制度，坚持以"让客户满意"为服务宗旨,主要从事网络技术服务，技术咨询，技术交流等等，为广大从事互联网行业的人们提供一整套的安全防护。公司拥有完整的、专业的运营推广团队和与多类型公司合作的经验，已给多家企业及个人带来订单和经济利益。
          </div>
          <div>公司精神：锐意进取追求卓越 经营理念:诚信为本，合作创新，让客户满意 服务理念.用心服务，客户至上!</div>
        </div>
      </div>
      <el-image class="home-firm-img" :src="require('@/assets/image/v2_q66w83.png')"></el-image>
    </div>
  </div>
</template>

<script>

export default {
  name: "Index",
  data () {
    return {
      serverList: [{
        name: '短剧备案',
        img: require("@/assets/image/01.png"),
      }, {
        name: '短剧推广',
        img: require("@/assets/image/02.jpg"),
      }, {
        name: '短剧模板',
        img: require("@/assets/image/03.jpeg")
      }],
      excellentList: [
        {
          name: '团队专业',
          label: '专业团队，快速办理短剧备案申请服务',
          img: require("@/assets/image/11.jpg"),
        }, {
          name: '方案完善',
          label: '完善短剧挂靠营销方案',
          img: require("@/assets/image/17.jpg"),
        }, {
          name: '模板多样',
          label: '丰富多样的拍摄模板',
          img: require("@/assets/image/3.jpg")
        }, {
          name: '经验丰富',
          label: '多年行业经验，助力短剧推广',
          img: require("@/assets/image/13.jpg")
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
    to (index) {
      this.$store.commit('setSerIndex', index)
      this.$router.push({ path: `server/index?i=${index}` }).catch(err => { })
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  &-title {
    font-size: 28px;
    padding: 60px 0 40px;
  }

  &-content {
    display: flex;
    justify-content: space-between;

    &-item {
      width: 30%;
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 28px;
      color: #999;
      cursor: pointer;

      .img {
        width: 100%;
        flex-shrink: 0;
        height: 200px;
        margin-bottom: 30px;
      }
    }
  }

  .excellent {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-item {
      display: flex;
      width: 100%;

      .img {
        width: 50%;
        height: 240px;
      }

      &-text {
        width: 50%;
        background-color: #fff;
        padding: 60px 80px;
        height: 240px;
        box-sizing: border-box;
        position: relative;

        .name {
          font-size: 26px;
          font-weight: bold;
          position: relative;
          z-index: 2;
        }

        .label {
          font-size: 12px;
          color: #999;
          margin-top: 20px;
          position: relative;
          z-index: 2;
        }

        .position {
          font-size: 42px;
          font-weight: 700;
          color: rgba(221, 221, 221, 1);
          position: absolute;
          left: 80px;
          top: 34px;
          z-index: 1;
        }
      }
    }

    .reverse {
      flex-direction: row-reverse;
    }
  }

  &-firm {
    box-sizing: border-box;
    width: 100%;
    margin: 0 0 30px;
    display: flex;
    position: relative;

    &-info {
      width: 80%;
      padding: 40px 220px 40px 40px;
      box-sizing: border-box;
      color: #fff;
      position: relative;
      z-index: 2;

      .name {
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 30px;
      }
    }

    .backg {
      width: 80%;
      height: 100%;
      background-color: #0157b9;
      position: absolute;
      z-index: 1;
    }

    &-img {
      width: calc(20% + 180px);
      height: 200px;
      position: absolute;
      z-index: 2;
      left: calc(80% - 180px);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
